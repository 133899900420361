import React, { Component } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import Scroll from 'react-scroll';

const Link = Scroll.Link;

export default class NavbarComp extends Component {
    constructor (props){
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
    }
    scrollToTop(){}
  
    state = {
      expanded: {}
    }
  
    setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
    }
  
    closeNav = () => {
    this.setState({ navExpanded: false });
    }

    render() {
        return (
                <Navbar className='body width-100-per' bg="white" variant={"light"} expand="lg" sticky="top" onToggle={this.setNavExpanded}
                expanded={this.state.navExpanded}>
                    <Container>
                        <Navbar.Brand as={Link} to='home' href='' spy={true} smooth={true} duration={1}>
                            <img alt='' className='nav-image' src='images/logo0.jpg'/>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav" >
                            <Nav className="mr-auto">
                                <Nav.Link className='header-text-color' onSetActive={this.closeNav} as={Link} to='about' href='' spy={true} smooth={true} duration={1}>About</Nav.Link>
                                <span className="mycontent-left"></span>
                                <Nav.Link className='header-text-color' onSetActive={this.closeNav} as={Link} to='member' href='' spy={true} smooth={true} duration={1}>Become A Member</Nav.Link>
                                <span className="mycontent-left"></span>
                                <Nav.Link className='header-text-color' onSetActive={this.closeNav} as={Link} to='download' href='' spy={true} smooth={true} duration={1}>Download</Nav.Link>
                                <span className="mycontent-left"></span>
                                <Nav.Link className='header-text-color' onSetActive={this.closeNav} as={Link} to='donate' href='' spy={true} smooth={true} duration={1}>Donate</Nav.Link>
                                <span className="mycontent-left"></span>
                                <Nav.Link className='header-text-color' onSetActive={this.closeNav} as={Link} to='contact' href='' spy={true} smooth={true} duration={1}>Contact</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
        )
    }
}
