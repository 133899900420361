import React, { Component } from 'react'
import { Col, Container, Row, Image, Button } from 'react-bootstrap'
import { isAndroid, isIOS, isDesktop } from "react-device-detect";

export default class Download extends Component {

    onDownload = () => {
        var now = new Date().valueOf();
        setTimeout(function () {
            if (new Date().valueOf() - now > 100) {
                if (isAndroid) {
                    window.location.href = "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1";
                } else if (isIOS) {
                    window.location.href = "https://apps.apple.com/us/app/my/id1603899001";
                } else if (isDesktop){
                    window.location.href = "#download2";
                }
            }
        }, 500);
        window.location = 'myapp://';
    }

    checkAppAndroid = () => {
        var now = new Date().valueOf();
        setTimeout(function () {
            if (new Date().valueOf() - now > 100) {
                if (isAndroid) {
                    window.open("https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1");
                } else if (isIOS) {
                    window.open("https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1");
                } else if (isDesktop){
                    window.open("https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1");
                }
            }
        }, 500);
        window.location = 'myapp://';
    }

    checkAppApple = () => {
        var now = new Date().valueOf();
        setTimeout(function () {
            if (new Date().valueOf() - now > 100) {
                if (isAndroid) {
                    window.open("https://apps.apple.com/us/app/my/id1603899001");
                } else if (isIOS) {
                    window.open("https://apps.apple.com/us/app/my/id1603899001");
                } else if (isDesktop){
                    window.open("https://apps.apple.com/us/app/my/id1603899001");
                }
            }
        }, 500);
        window.location = 'myapp://';
    }

    render() {
        return (
                <div className='body width-100-per' id='download'>
                    <Container className='download1-container-style'>
                        <Container className='download1-container-title'>
                            <h3 className='rem3-6-text-size text-bold ml-4-per'>Download My...</h3>
                        </Container>

                        <Container>
                            <Row>
                                <Col xs="12" lg="6">
                                    <Container>
                                        <h1 className='download-text-1 rem3-text-size mt-5 ml-6-per'>Share your talent with</h1>
                                        <h1 className='download-text-1 rem3-text-size ml-6-per' style={{marginBottom: '20%'}}>others on your device!</h1>
                                    </Container>
                                    <Container>
                                        <div className='text-center'>
                                            <Button className='home-download-button rem2--5-text-size' onClick={this.onDownload}>
                                                Download
                                            </Button>
                                        </div>  
                                    </Container>

                                </Col>
                                <Col xs="12" lg="6">
                                    <Container className='text-center'>
                                        <Image src='images/download1.png' fluid/>
                                    </Container>
                                </Col>
                                <Col xs="12" lg="12" id='download2'>
                                    <h1 className='download-text-2 rem3-text-size text-align-left ml-4-per'>Bring your MY... to mobile!</h1>
                                </Col>
                                <Col xs="12" lg="12">
                                    <h1 className='download-text-2 rem3-text-size text-align-left ml-4-per'>Available on App Store & Play Store</h1>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <Container className='download2-container-style pt-5 pb-5'>
                        <Container className=''>
                            <Row>
                                <Col xs="6" lg="6" className='text-center'>
                                    <Image src='images/App store Button.png' fluid onClick={this.checkAppApple}/>
                                </Col>
                                <Col xs="6" lg="6" className='text-center'>
                                    <Image className='text-right' onClick={this.checkAppAndroid} src='images/Google Play Button.png' fluid/>
                                </Col>
                                <Col xs="6" lg="6" className='text-center mt-5'>
                                    <Image className='text-right' src='images/apple_store_1.png' fluid/>
                                </Col>
                                <Col xs="6" lg="6" className='text-center mt-5'>
                                    <Image className='text-right' src='images/google_play_store _1.png' fluid/>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </div>
        )
    }
}
