import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

export default class Footer extends Component {
  render() {
    return (
      <section className="mt-0">
        <div className="body width-100-per">
          <Container className="contact-container1-style">
            <Container>
              <Row>
                <Col xs="12" lg="12">
                  <div className="text-center">
                    <label
                      title=""
                      htmlFor="validationFormik110"
                      className="form-check-label rem1-text-size"
                    >
                      <a
                        className="white-color"
                        rel="noreferrer"
                        href={"https://my.greggii.com/policy.pdf"}
                        target={"_blank"}
                      >
                        {" "}
                        Privacy Policy & Terms and Condition
                      </a>
                    </label>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </div>
      </section>
    );
  }
}
