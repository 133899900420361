import React, { Component } from "react";
import { Button, Container, Row, Col, Image } from "react-bootstrap";
import { isAndroid, isIOS, isDesktop } from "react-device-detect";

export default class About extends Component {
  onDownload = () => {
    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 100) {
        if (isAndroid) {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1";
        } else if (isIOS) {
          window.location.href =
            "https://apps.apple.com/us/app/my/id1603899001";
        } else if (isDesktop) {
          window.location.href = "#download2";
        }
      }
    }, 500);
    window.location = "myapp://";
  };

  render() {
    return (
      <div className="body width-100-per" id="about">
        <Container className="home2-container-style">
          <Container className="home2-container-title rem3-text-size">
            <h3 className="rem3-text-size text-bold ml-3-per">Who We Are...</h3>
          </Container>
          <Container className="mt-5">
            <Row>
              <Col xs="12" lg="7">
                <p className="rem2-5-text-size height-line text-justify ml-6-per">
                  My… By Greggii is THE PLACE to connect with our community
                  members who share your common interests. Through My… you can …
                  Meet our pets’ parents in our community, and join our social
                  gatherings and events for our pets and our pets’ parents. Join
                  your favorite interest with members from either your local
                  community or from across the globe, Discuss your common
                  interests, obtain opinions, and explore ideas from others,
                  Participate in private chats with other members, Find your
                  best offers for your most favorable Products and Services on
                  My… Marketplace, Create a business profile, Create your
                  products or services, Attend personal events with other
                  members who share the same interest as you, Join us in
                  supporting the talent of our youth, and More. We are committed
                  to making your experience as pleasant as it can be, Join Us
                  Now.{" "}
                </p>
              </Col>
              <Col xs="12" lg="5">
                <Container className="text-center">
                  <Image
                    src="images/logo3.png"
                    className=""
                    fluid
                    roundedCircle
                  />
                </Container>
              </Col>
              <Col xs="12" lg="12" className="text-center">
                <Button
                  className="home2-download-button rem2--5-text-size"
                  onClick={this.onDownload}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className="home3-container-style">
          <Row>
            <Col xs="12" lg="12">
              <Container className="home3-container-title rem3-text-size">
                <h3 className="rem3-text-size text-bold ml-3-per">
                  Our Mission...
                </h3>
              </Container>
            </Col>
            <Col xs="12" lg="6" className="MV-set-margin">
              <Container className="set-margin-container">
                <p className="rem2-5-text-size text-justify height-line  ml-6-per">
                  As advocates who are extremely passionate about empowering,
                  promoting and supporting the local community through
                  meaningful talent acknowledgement and recognition, mY…By
                  Greggii’s mission is to give youth the opportunity to share
                  and showcase their talents to empower and inspire each to
                  create a healthy social environment and community. Through
                  initiatives and events that focus on highlighting, supporting
                  and sponsoring these individuals, we want to help promote
                  individual values, eliminate stigma around mental health, and
                  help jumpstart future careers.
                </p>
              </Container>
            </Col>
            <Col xs="12" lg="6" className="MV-set-margin">
              <Container className="set-margin-container text-center">
                <Image src="images/our-mission.png" fluid />
              </Container>
            </Col>
          </Row>
        </Container>
        <Container className="home4-container-style">
          <Row>
            <Col xs="12" lg="12">
              <Container className="home4-container-title rem3-text-size">
                <h3 className="rem3-text-size text-bold ml-3-per">
                  Our Vision...
                </h3>
              </Container>
            </Col>
            <Col xs="12" lg="6" className="MV-set-margin">
              <Container className="set-margin-container">
                <p className="rem2-5-text-size text-justify height-line ml-6-per">
                  Our vision is to promote the value of individuals to create
                  and encourage social interaction based on real connection and
                  communication. By creating a healthy social environment and
                  sponsoring talented youth, we can help facilitate growth and
                  success.
                </p>
              </Container>
            </Col>
            <Col xs="12" lg="6" className="MV-set-margin">
              <Container className="text-center">
                <Image src="images/our-vision.png" fluid />
              </Container>
            </Col>
            <Col
              xs="12"
              lg="12"
              className="text-center"
              style={{ marginTop: "5%" }}
            >
              <Button
                className="about-download-button rem2--5-text-size"
                onClick={this.onDownload}
              >
                Download
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
