import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import 'react-phone-input-2/lib/material.css'

export default class Member extends Component {
    render() {
        return (
            <div className='body width-100-per' id='member'>
                <Container className='member-container1-style'>
                    <Container className='member1-container-title rem3-text-size'>
                        <h1 className='rem3-6-text-size text-bold ml-4-per'>Become A Member...</h1>
                    </Container>

                    <Container className='home2-container-title '>
                        <h3 className='rem3-text-size text-bold ml-4-per'>Who are we looking for?</h3>
                    </Container>

                    <Container>
                        <Row>
                            <Col xs="12" lg="7">
                                <Container style={{paddingLeft: '8%'}}>
                                    <p className='rem2-5-text-size text-justify mt-4 height-line'>Do you have a talent you want to share with others? Become a member and part of a fast growing
    community of talented people! We want to recognize your potential and want to help facilitate
    growth and excellence within and across our local communities!</p>
                                    <p className='rem2-5-text-size text-justify mt-4 height-line'>Are you a local small/ medium sized business looking to get involved, support local youth, and
contribute to the economic growth within your community? Become a partner to help support
local youth fulfil their dreams.</p>
                                </Container>
                            </Col>
                            <Col xs="12" lg="5">
                                <Container className='text-center'>
                                    <Image src='images/logo5.png' fluid/>
                                </Container>
                            </Col>
                            <Col xs="12" lg="10">
                                
                            </Col>
                        </Row>
                    </Container>

                    <Container className='home2-container-title'>
                        <h3 className='rem3-text-size text-bold ml-4-per'>Volunteer and internship opportunities!</h3>
                    </Container>

                    <Container>
                        <Row>
                            <Col xs="" lg="10">
                                <p className='rem2-5-text-size text-justify mt-4 height-line ml-5-per'>We are committed to helping individuals maximize their
potential, and we want you to join our growing team!
                                </p>
                                <p className='rem2-5-text-size text-justify mt-4 height-line ml-5-per'>
                                    Contact us today for more information.
                                </p>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='home2-container-title'>
                        <h3 className='rem3-text-size text-bold ml-4-per'>Current positions include:</h3>
                    </Container>

                    <Container>
                        <Row>
                            <Col xs="" lg="10">
                                <Container style={{paddingLeft: '5%'}}>
                                    <p className='rem2-5-text-size text-justify mt-4'>
                                        <b>Internships:</b> location representatives
                                    </p>
                                    <p className='rem2-5-text-size text-justify'>
                                        <b>Volunteers:</b> event coordination and support
                                    </p>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        )
    }
}
