import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarComp from "./Components/NavbarComp";
import Home from "./Components/Home";
import About from "./Components/About";
import Member from "./Components/Member";
import Download from "./Components/Download";
import Contact from "./Components/Contact";
import { isAndroid, isIOS, isDesktop } from "react-device-detect";
import { useEffect } from "react";
import Footer from "./Components/Footer";
import Donate from "./Components/Donate";

function App() {
  useEffect(() => {
    var deep_link_url = window.location.search;
    var now = new Date().valueOf();
    if (deep_link_url !== "") {
      // //variable will check app installed or not
      var change = false;
      setTimeout(() => {
        if (!change) {
          var redirectUrl = "";
          if (isAndroid) {
            redirectUrl =
              "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1";
          } else if (isIOS) {
            redirectUrl = "https://apps.apple.com/us/app/my/id1603899001";
          } else if (isDesktop) {
            redirectUrl = "https://my.greggii.com/";
          }
          window.location = redirectUrl;
        }
      }, 3000);
      window.location = "myapp://" + deep_link_url;
      //handle event to check app installed or not
      window.onblur = function () {
        change = true;
      };
      window.onfocus = function () {
        change = false;
      };
    } else {
      if (
        (isAndroid || isIOS) &&
        window.confirm("Would you like to visit the App Store?")
      ) {
        setTimeout(function () {
          if (new Date().valueOf() - now > 100) {
            if (isAndroid) {
              window.location.href =
                "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1";
            } else if (isIOS) {
              window.location.href =
                "https://apps.apple.com/us/app/my/id1603899001";
            } else if (isDesktop) {
              window.location.href = "https://my.greggii.com/";
            }
          }
        }, 500);
      }
    }
  }, []);

  return (
    <div className="App">
      <NavbarComp />

      <Home />

      <About />

      <Member />

      <Download />

      <Donate />

      <Contact />

      <Footer />
    </div>
  );
}

export default App;
