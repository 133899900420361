import React, { Component } from 'react'
import {Button, Container, Row, Col, Image } from 'react-bootstrap'
import { isAndroid, isIOS, isDesktop } from "react-device-detect";

export default class Home extends Component {

    onDownload = () => {
        var now = new Date().valueOf();
        setTimeout(function () {
            if (new Date().valueOf() - now > 100) {
                if (isAndroid) {
                    window.location.href = "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1";
                } else if (isIOS) {
                    window.location.href = "https://apps.apple.com/us/app/my/id1603899001";
                } else if (isDesktop){
                    window.location.href = "#download2";
                }
            }
        }, 500);
        window.location = 'myapp://';
    }


    render() {
        return (
            <div className='body width-100-per' id='home'>
                <Container className='Home-Container'>
                        <Row className='text-align-left mt-5'>
                            <Col xs="12" lg="6" >
                                <Container className='set-margin-container'>
                                    <h1 className='home-text-1 rem3-4-text-size ml-6-per'>Let your talent blossom</h1>
                                    <h1 className='home-text-2 rem5-2-text-size ml-6-per'>Bloom with us!</h1>

                                    <div className='text-center'>
                                        <Button className='home-download-button rem2--5-text-size mt-20-per mb-20-per' onClick={this.onDownload}>
                                            Download
                                        </Button>
                                        
                                    </div>
                                </Container>
                            </Col>
                            <Col xs="12" lg="6">
                                <Container className='text-center'>
                                    <Image src='images/logo3.png' fluid/>
                                </Container>
                                
                            </Col>
                            
                        </Row>
                </Container>
            </div>
        )
    }
}
