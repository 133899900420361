import { Col, Container, Row, Button, Image } from "react-bootstrap";
import React, { Component } from 'react'

export default class Donate extends Component {

    onDonate = () => {
        window.open("https://www.paypal.com/donate/?hosted_button_id=THAT3F39S5P6U");
    }

    render() {
        return(
            <div className='body width-100-per' id='donate'>
                <Container className='download1-container-style'>
                    <Container className='download1-container-title'>
                        <h3 className='rem3-6-text-size text-bold ml-4-per'>Donate</h3>
                    </Container>
                </Container>

                <Container>
                    <Row>
                        <Col xs="12" lg="6">
                            <Container className='set-margin-container'>
                                <p className='rem2-5-text-size text-justify height-line ml-6-per orange-color'>Your support will help My... continue to organize community events that help discover, promote and grow talented individuals.</p>
                            </Container>
                        </Col>
                        
                        <Col xs="12" lg="6" className='text-center'>
                            <Image className='text-right' src='images/donate_qr.png' fluid/>
                        </Col>
                    </Row>
                </Container>

                <Container style={{marginBottom: 50,marginTop: 50}}>
                    <div className='text-center'>
                        <Button className='donate-button rem2--5-text-size' onClick={this.onDonate}>
                            Donate 
                        </Button>
                    </div>  
                </Container>
            </div>
        )
    }
}